<template>
    <div class="autocompleter-transform-find-blocker">
        <v-autocomplete
            v-model="model"
            :items="items"
            :loading="loading"
            :search-input.sync="search"
            item-text="workerFullname"
            item-value="workerId"
            :label="(label != null) ? label : lviews.chooseWorker"
            hide-no-data
            :placeholder="lviews.startWritingToFind"
            :disabled="disabled"
            clearable
            :prepend-icon="prependIcon"
            :append-icon="'mdi-magnify'"
            :error="(errors != null && errors.length > 0) || error"
            :error-messages="(error) ? errorMessages : errors"
        >
            <template v-slot:item="data">
                <v-list-item-content style="margin: 0px; padding-left: 10px;">
                    <v-list-item-title v-html="data.item.workerFullname"></v-list-item-title>
                    <v-list-item-subtitle>Ext. ID:  
                        <span v-if="data.item.personalId">{{ data.item.personalId }} </span>
                        <span v-else>-</span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
//import axios from 'axios';
const axios = require('axios');
import appConfig from '@/app_config'

export default {
    name: "UsersWorkerAutocompleter",
    data: function() {
        return {
            model: null,
            search: "",
            searchTimeout: null,
            preventUpdate: null,
            loading: false,
            errors: [],
            items: [

            ],
            disabled: false
        }
    },
    watch: {
        search: function() {
            if (!this.preventUpdate) {
                if (this.searchTimeout != null) {
                    clearTimeout(this.searchTimeout);
                }
                var that = this;
                this.searchTimeout = setTimeout(function(){
                    that.invokeUpdateAutocomplete();
                }, 500);
            }
            else {
                this.preventUpdate = false;
            }
        },
        model: function() {
            var id = this.model;
            var item = null;
            for(let i = 0; i < this.items.length; i++){
                if(this.items[i].workerId == id){
                    item = this.items[i];
                }
            }
            this.$emit("input", this.model, item);
        },
        outerModel: function() {
            this.model = this.outerModel;
        },
        value: function(){
            this.model = this.value;
        }
    },
    methods: {
        reset(){

        },
        invokeUpdateAutocomplete: function() {
            this.apiUpdateAutocomplete(this.search);
        },

        apiUpdateAutocomplete: function(search) {
            this.loading = true;
            var that = this;
            let requestUrl = appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/worker-no-user/";

            let params = {
                page: 1,
                per_page:100,
            }
            if(this.search!=null && this.search!=""){
                params.personal_data_search= search
            }

            axios({
                method: "GET",
                url: requestUrl,
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem('jwt')
                },
                params: params
            }).then( response => {
                let workers=response.data
                that.items = [];
                for(var i = 0; i <workers.length; i++) {
                    var item = {
                        workerId: workers[i].id,
                        workerFullname: workers[i].personal_data.full_name,
                        birthDate: workers[i].personal_data.birth_date,
                        lastName: workers[i].personal_data.last_name,
                        firstName: workers[i].personal_data.first_name,
                        email: workers[i].personal_data.email,
                        phoneNumber: workers[i].personal_data.phone_number,
                        personalId: workers[i].personal_data.unique_personal_id,
                        comment: workers[i].personal_data.comment
                    };

                    that.items.push(item);
                }
                that.loading = false;
            }).catch( error => {
                that.loading = false;
                if (error.isAxiosError) {
                    that.errors.push(this.lviews.sorryWeCouldntConnectToServer);
                }
                else {
                    that.errors.push(error.toString());
                }
            });
        },
    },
    computed: {
        lviews: {
            get: function() {
                return this.$t("views.documents");
            }
        },
    },
    mounted: function() {
        let specificWorkerDocumentsSearchValueFromUserProfile = localStorage.getItem("Specific_worker_documents_search_value");
        if (specificWorkerDocumentsSearchValueFromUserProfile != null) {
            localStorage.removeItem("Specific_worker_documents_search_value");
            this.search = specificWorkerDocumentsSearchValueFromUserProfile;
            this.apiUpdateAutocomplete(this.search);
        }
        else {
            this.apiUpdateAutocomplete("");
        }
    },
    props: {
        value: {
            type: Number,
            required: false,
            default: null
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        errorMessages: {
            required: false,
            default: null
        },
        prependIcon: {
            type: String,
            required: false,
            default: ''
        },
        propApiLink: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        // worker autocompleter CRUD permissions (info if they should be passed in a link)
        addPermissionRequired: {
            type: Boolean,
            required: false,
            default: null,
        },
        viewPermissionRequired: {
            type: Boolean,
            required: false,
            default: null,
        },
        changePermissionRequired: {
            type: Boolean,
            required: false,
            default: null,
        },
        deletePermissionRequired: {
            type: Boolean,
            required: false,
            default: null,
        }
    }
}
</script>

<style>
.autocompleter-transform-find-blocker .v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: none !important;
}
</style>